import React, { useEffect, useState } from "react";
import classes from './Faq.module.scss';
import { useTranslation } from "react-i18next";


const Faq = ({ currentTab }) => {
    const { t } = useTranslation();
    const tabs = [
        [
            {
                title: t("faq_1"),
                text: t("faq_2"),
            },
            {
                title: t("faq_3"),
                text: t("faq_4"),
            },
            {
                title: t("faq_5"),
                text: t("faq_6"),
            },
            {
                title: t("faq_7"),
                text: t("faq_8"),
            },
            {
                title: t("faq_9"),
                text: t("faq_10"),
            },
        ],
        [
            {
                title: t("faq_11"),
                text: t("faq_12"),
            },
            {
                title: t("faq_13"),
                text: t("faq_14"),
            },
            {
                title: t("faq_15"),
                text: t("faq_16"),
            },
            {
                title: t("faq_17"),
                text: t("faq_18"),
            },
            {
                title: t("faq_19"),
                text: t("faq_20"),
            },
        ],
        [
            {
                title: t("faq_21"),
                text: t("faq_22"),
            },
            {
                title: t("faq_23"),
                text: t("faq_24"),
            },
            {
                title: t("faq_25"),
                text: t("faq_26"),
            },
            {
                title: t("faq_27"),
                text: t("faq_28"),
            },
            {
                title: t("faq_29"),
                text: t("faq_30"),
            },
        ],
    ];
    const [active, setActive] = useState(0);
    const openTab = (index) => {
        if (active === index) {
            setActive(-1);
            return;
        }
        setActive(index);
    };
    useEffect(() => {
        setActive(0);
    }, [currentTab]);
    return (
        <section className="mt container">
            <div className={classes.faq}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("main_52")}
                </h2>
                <div className={classes.tabs}>
                    {tabs[currentTab].map((tab, index) =>
                        <div
                            className={`${classes.tab} ${active === index && classes.tabActive} no-select font-16`}
                            onClick={() => openTab(index)}
                            key={index}
                        >
                            <div className={classes.tabHeader}>
                                <div className={classes.tabHeaderText}>
                                    {tab.title}
                                </div>
                                <div className={classes.tabStatus}></div>
                            </div>
                            <div className={classes.tabContent}>
                                <p className="font-16">
                                    {tab.text}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faq;
