import React, { useState } from "react";
import classes from './AvailableMarkets.module.scss';
import { Trans, useTranslation } from "react-i18next";
import icon_01_01 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_01_01.png';
import icon_01_02 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_01_02.png';
import icon_01_03 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_01_03.png';
import icon_01_04 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_01_04.png';
import icon_01_05 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_01_05.png';
import icon_01_06 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_01_06.png';
import icon_01_07 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_01_07.png';
import icon_02_01 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_02_01.png';
import icon_02_02 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_02_02.png';
import icon_02_03 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_02_03.png';
import icon_02_04 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_02_04.png';
import icon_02_05 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_02_05.png';
import icon_02_06 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_02_06.png';
import icon_02_07 from '../../../assets/img/ProductsPage/AvailableMarkets/icon_02_07.png';

const AvailableMarkets = () => {
    const { t } = useTranslation();
    const buttons = [
        t("products_17"),
        t("products_18"),
        t("products_19"),
        t("products_20"),
    ];

    const rows_01_01 = [
        [
            t("products_21"),
            t("products_22"),
            t("products_23"),
            t("products_24"),
            t("products_25"),
        ],
        [
            'EUR/USD',
            '1.7',
            '1.4',
            '1.1',
            '1%',
        ],
        [
            'USD/JPY',
            '1.6',
            '1.4',
            '1.2',
            '1%',
        ],
        [
            'GBP/USD',
            '2.0',
            '1.7',
            '1.4',
            '1%',
        ],
        [
            'USD/CHF',
            '2.0',
            '1.8',
            '1.6',
            '1%',
        ],
        [
            'USD/CAD',
            '2.7',
            '2.4',
            '2.2',
            '1%',
        ],
        [
            'AUD/USD',
            '1.6',
            '1.4',
            '1.2',
            '1%',
        ],
        [
            'NZD/USD',
            '2.3',
            '2.2',
            '1.8',
            '1%',
        ],
    ];

    const rows_01_02 = [
        [
            t("products_26"),
            t("products_27"),
            t("products_28"),
            t("products_29"),
            t("products_30"),
        ],
        [
            'EUR/GBP',
            '1.7',
            '1.5',
            '1.2',
            '1%',
        ],
        [
            'EUR/AUD',
            '2.8',
            '2.6',
            '2.3',
            '1%',
        ],
        [
            'GBP/JPY',
            '2.9',
            '2.7',
            '2.4',
            '1%',
        ],
        [
            'NZD/JPY',
            '1.7',
            '1.5',
            '1.3',
            '1%',
        ],
        [
            'GBP/CAD',
            '2.7',
            '2.4',
            '2.2',
            '1%',
        ],
        [
            'EUR/CHF',
            '2.4',
            '2.2',
            '1.9',
            '1%',
        ],
        [
            'EUR/JPY',
            '2.5',
            '2.3',
            '2.0',
            '1%',
        ],
    ];

    const rows_02_01 = [
        [
            t("products_31"),
            t("products_32"),
            t("products_33"),
            t("products_34"),
            t("products_35"),
        ],
        [
            'XAUUSD',
            '0.29',
            '0.26',
            '0.23',
            '2%',
        ],
        [
            'XPDUSD',
            '0.36',
            '0.33',
            '0.31',
            '20%',
        ],
        [
            'XPTUSD',
            '0.41',
            '0.38',
            '0.35',
            '20%',
        ],
        [
            'XAGUSD',
            '1.18',
            '1.13',
            '1.09',
            '5%',
        ],
    ];

    const rows_03_01 = [
        [
            t("products_36"),
            t("products_37"),
            t("products_38"),
            t("products_39"),
            t("products_40"),
        ],
        [
            t("products_41"),
            `USD 0.03/${t("products_42")}<br/>${t("products_43")} 20`,
            `USD 0.02/${t("products_42")}<br/>${t("products_43")} 15`,
            `USD 0.01/${t("products_42")}<br/>${t("products_43")} 10`,
            t("products_46"),
        ],
        [
            t("products_44"),
            `0.15%<br/>${t("products_43")} 20`,
            `0.12%<br/>${t("products_43")} 15`,
            `0.09%<br/>${t("products_43")} 10`,
            t("products_46"),
        ],
        [
            t("products_45"),
            `0.15%<br/>${t("products_43")} 20`,
            `0.12%<br/>${t("products_43")} 15`,
            `0.09%<br/>${t("products_43")} 10`,
            t("products_46"),
        ],
        [
            t("products_47"),
            `0.15%<br/>${t("products_43")} 20`,
            `0.12%<br/>${t("products_43")} 15`,
            `0.09%<br/>${t("products_43")} 10`,
            t("products_46"),
        ],
        [
            t("products_48"),
            `0.15%<br/>${t("products_43")} 20`,
            `0.12%<br/>${t("products_43")} 15`,
            `0.09%<br/>${t("products_43")} 10`,
            t("products_46"),
        ],
    ];

    const rows_03_02 = [
        [
            t("products_31"),
            t("products_32"),
            t("products_33"),
            t("products_34"),
            t("products_35"),
        ],
        [
            '#US500',
            '1.15',
            '0.85',
            '0.65',
            '2%',
        ],
        [
            'XPDUSD',
            '2.20',
            '1.90',
            '1.70',
            '2%',
        ],
        [
            'XPTUSD',
            '3.70',
            '3.40',
            '3.20',
            '2%',
        ],
        [
            'XAGUSD',
            '2.0',
            '1.8',
            '1.6',
            '2%',
        ],
        [
            'XAGUSD',
            '5.70',
            '5.40',
            '5.20',
            '2%',
        ],
        [
            'XAGUSD',
            '0.05',
            '0.04',
            '0.03',
            '4%',
        ],
        [
            'XPTUSD',
            '0.05',
            '0.05',
            '0.05',
            '4%',
        ],
        [
            'XAGUSD',
            '0.05',
            '0.05',
            '0.05',
            '4%',
        ],
        [
            'XAGUSD',
            '0.05',
            '0.05',
            '0.05',
            '4%',
        ],
        [
            'XAGUSD',
            '0.05',
            '0.05',
            '0.05',
            '4%',
        ],
    ];

    const rows_04_01 = [
        {
            icon: icon_01_01,
            title: t("products_49"),
            text: t("products_50"),
        },
        {
            icon: icon_01_02,
            title: t("products_51"),
            text: t("products_52"),
        },
        {
            icon: icon_01_03,
            title: t("products_53"),
            text: t("products_54"),
        },
        {
            icon: icon_01_04,
            title: t("products_55"),
            text: t("products_56"),
        },
        {
            icon: icon_01_05,
            title: t("products_57"),
            text: t("products_58"),
        },
        {
            icon: icon_01_06,
            title: t("products_59"),
            text: t("products_60"),
        },
        {
            icon: icon_01_07,
            title: t("products_61"),
            text: t("products_62"),
        },
    ];

    const rows_04_02 = [
        {
            icon: icon_02_01,
            title: t("products_63"),
            text: t("products_64"),
        },
        {
            icon: icon_02_02,
            title: t("products_65"),
            text: t("products_66"),
        },
        {
            icon: icon_02_03,
            title: t("products_67"),
            text: t("products_68"),
        },
        {
            icon: icon_02_04,
            title: t("products_69"),
            text: t("products_70"),
        },
        {
            icon: icon_02_05,
            title: t("products_71"),
            text: t("products_72"),
        },
        {
            icon: icon_02_06,
            title: t("products_73"),
            text: t("products_74"),
        },
        {
            icon: icon_02_07,
            title: t("products_75"),
            text: t("products_76"),
        },
    ];

    const [ currentTab, setCurrentTab ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.availableMarkets}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("products_14")}
                </h2>
                <div className={classes.buttons}>
                    {buttons.map((button, index) =>
                        <div 
                            key={index}
                            className={`${classes.button} ${index === currentTab && classes.buttonActive}`}
                            onClick={() => setCurrentTab(index)}
                        >
                            <span className="font-16 no-select">
                                {button}
                            </span>
                        </div>
                    )}
                </div>
                {currentTab === 0 &&
                    <div className={classes.tab}>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            {t("products_15")}
                        </p>
                        <div className={classes.tableContainer}>
                            <div className={classes.tableWrap}>
                                <table>
                                    <th colSpan="5">
                                        {t("products_16")}
                                    </th>
                                    {rows_01_01.map((row, rowIndex) =>
                                        <tr key={rowIndex}>
                                            {row.map((col, colIndex) =>
                                                <td key={colIndex}>
                                                    {col}
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </table>
                            </div>
                        </div>
                        <div className={classes.tableContainer}>
                            <div className={classes.tableWrap}>
                                <table>
                                    <th colSpan="5">
                                        {t("products_77")}
                                    </th>
                                    {rows_01_02.map((row, rowIndex) =>
                                        <tr key={rowIndex}>
                                            {row.map((col, colIndex) =>
                                                <td key={colIndex}>
                                                    {col}
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>                
                }

                {currentTab === 1 &&
                    <div className={classes.tab}>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            {t("products_78")}
                        </p>
                        <div className={classes.tableContainer}>
                            <div className={classes.tableWrap}>
                                <table>
                                    <th colSpan="5">
                                        {t("products_79")}
                                    </th>
                                    {rows_02_01.map((row, rowIndex) =>
                                        <tr key={rowIndex}>
                                            {row.map((col, colIndex) =>
                                                <td key={colIndex}>
                                                    {col}
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>                
                }

                {currentTab === 2 &&
                    <div className={classes.tab}>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            {t("products_80")}
                        </p>
                        <div className={classes.tableContainer}>
                            <div className={classes.tableWrap}>
                                <table>
                                    <th colSpan="5">
                                        {t("products_81")}
                                    </th>
                                    {rows_03_01.map((row, rowIndex) =>
                                        <tr key={rowIndex}>
                                            {row.map((col, colIndex) =>
                                                <td key={colIndex}>
                                                    <Trans>
                                                        {col}
                                                    </Trans>
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </table>
                            </div>
                        </div>
                        <div className={classes.tableContainer}>
                            <div className={classes.tableWrap}>
                                <table>
                                    <th colSpan="5">
                                        {t("products_82")}
                                    </th>
                                    {rows_03_02.map((row, rowIndex) =>
                                        <tr key={rowIndex}>
                                            {row.map((col, colIndex) =>
                                                <td key={colIndex}>
                                                    <Trans>
                                                        {col}
                                                    </Trans>
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>                
                }

                {currentTab === 3 &&
                    <div className={classes.tab}>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            {t("products_83")}
                        </p>
                        <div className={classes.cols}>
                            <div className={classes.col}>
                                {rows_04_01.map((row, index) =>
                                    <div key={index} className={classes.row}>
                                        <div className={classes.rowIcon}>
                                            <img src={row.icon} alt=''/>
                                        </div>
                                        <div className={classes.rowContent}>
                                            <p className={classes.rowTitle}>
                                                {row.title}
                                            </p>
                                            <p className={classes.rowText}>
                                                {row.text}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={classes.col}>
                                {rows_04_02.map((row, index) =>
                                    <div key={index} className={classes.row}>
                                        <div className={classes.rowIcon}>
                                            <img src={row.icon} alt=''/>
                                        </div>
                                        <div className={classes.rowContent}>
                                            <p className={classes.rowTitle}>
                                                {row.title}
                                            </p>
                                            <p className={classes.rowText}>
                                                {row.text}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>                
                }
            </div>
        </section>
    );
};

export default AvailableMarkets;
