import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/FaqPage/faq.png';


const Banner = ({ currentTab, setCurrentTab }) => {
    const { t } = useTranslation();
    const buttons = [
        t("promo_36"),
        t("promo_37"),
        t("promo_38"),
    ];
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={[classes.title, 'font-36'].join(' ')}>
                            {t("promo_35")}
                        </h1>
                        <div className={classes.buttons}>
                            {buttons.map((button, index) =>
                                <div 
                                    key={index} 
                                    className={`${classes.button} ${index === currentTab && classes.buttonActive} font-16 no-select`}
                                    onClick={() => setCurrentTab(index)}
                                >
                                    <span>
                                        {button}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <img className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
