import React, { useState } from "react";
import classes from './ContactUs.module.scss';
import buttonClasses from '../../GlobalComponents/Button/Button.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/FaqPage/contact_us.png';

const ContactUs = () => {
    const { t } = useTranslation();
    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const submit = () => {
        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (name === '') {
            alert('Empty name');
            return;
        }
        if (phone.length < 1) {
            alert('Invalid phone');
            return;
        }
        if (!expression.test(String(email.toLocaleLowerCase()))) {
            alert('Invalid email');
            return;
        }
        setName('');
        setPhone('');
        setEmail('');
        setTimeout(() => {
            alert('Thank you!');
        }, 500);
    };
    return (
        <section className="mt container">
            <div className={classes.contactUs}>
                <div className={classes.form}>
                    <h2 className={[classes.title, 'font-36'].join(' ')}>
                        {t('contact_us_title')}
                    </h2>
                    <div className={classes.content}>
                        <input 
                            type="text"
                            placeholder={t('contact_us_name')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input 
                            type="tel"
                            placeholder={t('contact_us_phone')}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                        <input 
                            type="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div 
                            className={[classes.btn, buttonClasses.button].join(' ')}
                            onClick={submit}
                        >
                            <span>
                                {t('btn_trade')}
                            </span>
                        </div>
                    </div>
                    <img className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
