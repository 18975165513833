import React from "react";
import classes from './OptimalTrading.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/PricingPage/optimal_trading.png';

const OptimalTrading = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.optimalTrading}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-32'].join(' ')}>
                        {t("pricing_26")}
                    </h2>
                    <ul className="font-16">
                        <li>
                            {t("pricing_27")}
                        </li>
                        <li>
                            {t("pricing_28")}
                        </li>
                        <li>
                            {t("pricing_29")}
                        </li>
                        <li>
                            {t("pricing_30")}
                        </li>
                        <li>
                            {t("pricing_31")}
                        </li>
                    </ul>
                    <div className={classes.btn}>
                        <Button>
                            {t("btn_open")}
                        </Button>
                    </div>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default OptimalTrading;
