import React from "react";
import classes from './UnderBanner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/PricingPage/UnderBanner/image_01.png';
import image_02 from '../../../assets/img/PricingPage/UnderBanner/image_02.png';
import image_03 from '../../../assets/img/PricingPage/UnderBanner/image_03.png';
import image_04 from '../../../assets/img/PricingPage/UnderBanner/image_04.png';
import image_05 from '../../../assets/img/PricingPage/UnderBanner/image_05.png';
import image_06 from '../../../assets/img/PricingPage/UnderBanner/image_06.png';

const UnderBanner = () => {
    const { t } = useTranslation();
    const cards = [
        {
            image: image_01,
            title: t("pricing_4"),
            text: `${t("pricing_5")} $5.00`,
        },
        {
            image: image_02,
            title: t("pricing_8"),
            text: `${t("pricing_5")} $5.00`,
        },
        {
            image: image_03,
            title: t("pricing_9"),
            text: `${t("pricing_5")} $5.00`,
        },
        {
            image: image_04,
            title: t("pricing_10"),
            text: t("pricing_6"),
        },
        {
            image: image_05,
            title: t("pricing_11"),
            text:  `${t("pricing_5")} $9.00`,
        },
        {
            image: image_06,
            title: t("pricing_12"),
            text: t("pricing_7"),
        },
    ];
    return (
        <section className="mt container">
            <div className={classes.underBanner}>
                {cards.map((card, index) =>
                    <div key={index} className={classes.card}>
                        <img className={classes.cardImage} src={card.image} alt=''/>
                        <h4 className={classes.cardTitle}>
                            {card.title}
                        </h4>
                        {/* <p className={classes.cardText}>
                            <Trans>
                                {card.text}
                            </Trans>
                        </p> */}
                    </div>
                )}
            </div>
        </section>
    );
};

export default UnderBanner;
