import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/tabs.png';

const Tabs = () => {
    const { t } = useTranslation();
    const tabs = [
        {
            title: t("about_18"),
            text: t("about_19"),
        },
        {
            title: t("about_20"),
            text: t("about_21"),
        },
        {
            title: t("about_22"),
            text: t("about_23"),
        },
        {
            title: t("about_24"),
            text: t("about_25"),
        },
        {
            title: t("about_26"),
            text: t("about_27"),
        },
    ];
    const [ currentTab, setCurrentTab ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.tabsWrap}>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index} 
                            className={`${classes.tab} ${index === currentTab && classes.tabActive}`}
                            onClick={() => setCurrentTab(index)}
                        >
                            <h4 className={classes.title}>
                                {tab.title}
                            </h4>
                            <p className={[classes.text, 'font-16'].join(' ')}>
                                {tab.text}
                            </p>
                        </div>
                    )}
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Tabs;
