import React from "react";
import classes from "./Competitive.module.scss";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import {useTranslation} from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Competitive = () => {
  const { t } = useTranslation();
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,

          scrub: 2,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,

          scrub: 3,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef3.current, {
        scrollTrigger: {
          trigger: col1.current,

          scrub: 5,
        },
        y: 0,
      });
    }
  }, [width]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.competitive, "mt"].join(" ")} ref={col1}>
      <div className={[classes.competitiveBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.competitiveTitle, "font-36"].join(" ")}>
            {t("main_9")}
          </h2>
          <p className={[classes.competitiveSubtitle, "font-16"].join(" ")}>
            {t("main_10")}
          </p>
          <p className={[classes.competitiveSubtitle, "font-16"].join(" ")}>
            {t("main_11")}
          </p>
          <div className={[classes.competitiveRow, ""].join(" ")}>
            <div
              className={[classes.competitiveRowColumn, ""].join(" ")}
              ref={monetRef1}
            >
              <p
                className={[classes.competitiveRowColumnTitle, "font-16"].join(
                  " "
                )}
              >
                {t("main_12")}
              </p>
              <p
                className={[
                  classes.competitiveRowColumnSubTitle,
                  "font-16",
                ].join(" ")}
              >
                {t("main_13")}
              </p>
            </div>
            <div
              className={[classes.competitiveRowColumn, ""].join(" ")}
              ref={monetRef2}
            >
              <p
                className={[classes.competitiveRowColumnTitle, "font-16"].join(
                  " "
                )}
              >
                {t("main_14")}
              </p>
              <p
                className={[
                  classes.competitiveRowColumnSubTitle,
                  "font-16",
                ].join(" ")}
              >
                {t("main_15")}
              </p>
            </div>
            <div
              className={[classes.competitiveRowColumn, ""].join(" ")}
              ref={monetRef3}
            >
              <p
                className={[classes.competitiveRowColumnTitle, "font-16"].join(
                  " "
                )}
              >
                {t("main_16")}
              </p>
              <p
                className={[
                  classes.competitiveRowColumnSubTitle,
                  "font-16",
                ].join(" ")}
              >
                {t("main_17")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competitive;
