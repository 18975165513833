import React, { useState } from "react";
import classes from './Faq.module.scss';
import { useTranslation } from "react-i18next";

const Faq = () => {
    const { t } = useTranslation();

    const tabs = [
        {
            title: t("about_faq_1"),
            text: t("about_faq_2"),
        },
        {
            title: t("about_faq_3"),
            text: t("about_faq_4"),
        },
        {
            title: t("about_faq_5"),
            text: t("about_faq_6"),
        },
        {
            title: t("about_faq_7"),
            text: t("about_faq_8"),
        },
        {
            title: t("about_faq_9"),
            text: t("about_faq_10"),
        },
        {
            title: t("about_faq_11"),
            text: t("about_faq_12"),
        },
        {
            title: t("about_faq_13"),
            text: t("about_faq_14"),
        },
        {
            title: t("about_faq_15"),
            text: t("about_faq_16"),
        },
        {
            title: t("about_faq_17"),
            text: t("about_faq_18"),
        },
        {
            title: t("about_faq_19"),
            text: t("about_faq_20"),
        },
    ];
    const [currentTab, setCurrentTab] = useState(0);
    const openTab = (index) => {
        if (currentTab === index) {
            setCurrentTab(-1);
            return;
        }
        setCurrentTab(index);
    };
    return (
        <section className="mt container">
            <div className={classes.faq}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("main_52")}
                </h2>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            className={`${classes.tab} ${currentTab === index && classes.tabActive} no-select font-16`}
                            onClick={() => openTab(index)}
                            key={index}
                        >
                            <div className={classes.tabHeader}>
                                <div className={classes.tabHeaderText}>
                                    {tab.title}
                                </div>
                                <div className={classes.tabStatus}></div>
                            </div>
                            <div className={classes.tabContent}>
                                <p className="font-16">
                                    {tab.text}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faq;
