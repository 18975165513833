import React from "react";
import classes from "./Crypto.module.scss";
import { useEffect, useState } from "react";
import img1 from "../../../assets/img/main/5/1.png";
import img2 from "../../../assets/img/main/5/2.png";
import img3 from "../../../assets/img/main/5/3.png";
import img4 from "../../../assets/img/main/5/4.png";
import img5 from "../../../assets/img/main/5/5.png";
import img6 from "../../../assets/img/main/5/6.png";
import {Trans, useTranslation} from "react-i18next";

const Crypto = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.crypto, "mt"].join(" ")}>
      <div
          className={
            width > 650
                ? [classes.cryptoBody, ""].join(" ")
                : [classes.cryptoBody, "container"].join(" ")
          }
      >
        <section className={[classes.cryptoRow, ""].join(" ")}>
          <div className={[classes.cryptoRowLeft, ""].join(" ")}>
            <h3 className={[classes.cryptoRowLeftTitle, ""].join(" ")}>
              {t("main_35")}
            </h3>
            <p className={[classes.cryptoRowLeftText, ""].join(" ")}>
              {t("main_36")}
            </p>
          </div>
          <div className={[classes.cryptoRowRight, ""].join(" ")}>
            <img src={img1} alt="ETFs"/>
          </div>
        </section>
        <section className={[classes.cryptoRow, ""].join(" ")}>
          <div className={[classes.cryptoRowRight, ""].join(" ")}>
            <img src={img2} alt="Stocks"/>
          </div>
          <div className={[classes.cryptoRowLeft, ""].join(" ")}>
            <h3 className={[classes.cryptoRowLeftTitle, ""].join(" ")}>
              {t("main_37")}
            </h3>
            <p className={[classes.cryptoRowLeftText, ""].join(" ")}>
              <Trans>main_38</Trans>
            </p>
          </div>
        </section>
        <section className={[classes.cryptoRow, ""].join(" ")}>
          <div className={[classes.cryptoRowLeft, ""].join(" ")}>
            <h3 className={[classes.cryptoRowLeftTitle, ""].join(" ")}>
              {t("main_39")}
            </h3>
            <p className={[classes.cryptoRowLeftText, ""].join(" ")}>
              {t("main_40")}
            </p>
          </div>
          <div className={[classes.cryptoRowRight, ""].join(" ")}>
            <img src={img3} alt="ETFs"/>
          </div>
        </section>
        <section className={[classes.cryptoRow, ""].join(" ")}>
          <div className={[classes.cryptoRowRight, ""].join(" ")}>
            <img src={img4} alt="Indices"/>
          </div>
          <div className={[classes.cryptoRowLeft, ""].join(" ")}>
            <h3 className={[classes.cryptoRowLeftTitle, ""].join(" ")}>
              {t("main_41")}
            </h3>
            <p className={[classes.cryptoRowLeftText, ""].join(" ")}>
              {t("main_42")}
            </p>
          </div>
        </section>
        <section className={[classes.cryptoRow, ""].join(" ")}>
          <div className={[classes.cryptoRowLeft, ""].join(" ")}>
            <h3 className={[classes.cryptoRowLeftTitle, ""].join(" ")}>
              {t("main_43")}
            </h3>
            <p className={[classes.cryptoRowLeftText, ""].join(" ")}>
              {t("main_44")}
            </p>
          </div>
          <div className={[classes.cryptoRowRight, ""].join(" ")}>
            <img src={img5} alt="Cryptocurrencies"/>
          </div>
        </section>
        <section className={[classes.cryptoRow, ""].join(" ")}>
          <div className={[classes.cryptoRowRight, ""].join(" ")}>
            <img src={img6} alt="Commodities"/>
          </div>
          <div className={[classes.cryptoRowLeft, ""].join(" ")}>
            <h3 className={[classes.cryptoRowLeftTitle, ""].join(" ")}>
              {t("main_45")}
            </h3>
            <p className={[classes.cryptoRowLeftText, ""].join(" ")}>
              {t("main_46")}
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Crypto;
