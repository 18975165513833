import React, { useEffect, useRef } from "react";
import classes from './OurHistory.module.scss';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { CustomEase } from 'gsap/src/all';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

const OurHistory = () => {
    const { t } = useTranslation();

    const cards = [
        {
            year: '2015',
            text: t("about_6"),
        },
        {
            year: '2016',
            text: t("about_7"),
        },
        {
            year: '2017',
            text: t("about_8"),
        },
        {
            year: '2020',
            text: t("about_9"),
        },
        {
            year: '2022',
            text: t("about_10"),
        },
    ];

    const cardsLeft = [];
    const cardsRight = [];

    cardsLeft.push(cards[0]);
    cardsLeft.push(cards[2]);
    cardsLeft.push(cards[4]);

    cardsRight.push(cards[1]);
    cardsRight.push(cards[3]);

    const bodyRef = useRef(null);
    const cardsRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().to(cardsRef.current, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=2000px",
                    scrub: true,
                    invalidateOnRefresh: true,
                    pin: bodyRef.current,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={bodyRef} className={classes.ourHistory}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("about_4")}
                </h2>
                <p className={[classes.text, 'font-16'].join(' ')}>
                    {t("about_5")}
                </p>
                <div className={classes.cardsContainer}>
                    <div className={classes.line}>
                        <div className={classes.circle}></div>
                    </div>
                    <div ref={cardsRef} className={classes.cardsWrap}>
                        <div className={[classes.cardsLeft, classes.cards].join(' ')}>
                            {cardsLeft.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <div className={classes.cardContent}>
                                        <p className={[classes.cardYear, 'font-16'].join(' ')}>
                                            {card.year}
                                        </p>
                                        <p className={classes.cardText}>
                                            {card.text}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={[classes.cardsRight, classes.cards].join(' ')}>
                            {cardsRight.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <div className={classes.cardContent}>
                                        <p className={[classes.cardYear, 'font-16'].join(' ')}>
                                            {card.year}
                                        </p>
                                        <p className={classes.cardText}>
                                            {card.text}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes.swiperWrap}>
                    <Swiper
                        modules={[Pagination]}
                        className="ourHistorySwiper no-select"
                        spaceBetween={20}
                        slidesPerView={1}
                        grabCursor
                        pagination={{ clickable: true }}
                        breakpoints={{
                            700: {
                                slidesPerView: 2,
                            },
                            1220: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="ourHistorySwiperSlide">
                                <div key={index} className={classes.card}>
                                    <div className={classes.cardContent}>
                                        <p className={[classes.cardYear, 'font-16'].join(' ')}>
                                            {card.year}
                                        </p>
                                        <p className={classes.cardText}>
                                            {card.text}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default OurHistory;
