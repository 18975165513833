import React from "react";
import classes from './FirstBonus.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import arrow_01 from '../../../assets/img/PromotionsPage/FirstBonus/arrow_01.png';
import arrow_02 from '../../../assets/img/PromotionsPage/FirstBonus/arrow_02.png';

const FirstBonus = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.firstBonus}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("promo_28")}
                </h2>
                <div className={[classes.cards, 'font-16'].join(' ')}>
                    <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            <p className={classes.cardNum}>
                                1
                            </p>
                            <h4 className={classes.cardTitle}>
                                {t("promo_29")}
                            </h4>
                        </div>
                        <p className={classes.cardText}>
                            {t("promo_30")}
                        </p>
                    </div>
                    <div className={[classes.arrow, classes.arrow_01].join(' ')}>
                        <img src={arrow_01} alt=''/>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            <p className={classes.cardNum}>
                                2
                            </p>
                            <h4 className={classes.cardTitle}>
                                {t("promo_31")}
                            </h4>
                        </div>
                        <p className={classes.cardText}>
                            {t("promo_32")}
                        </p>
                    </div>
                    <div className={[classes.arrow, classes.arrow_02].join(' ')}>
                        <img src={arrow_02} alt=''/>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            <p className={classes.cardNum}>
                                3
                            </p>
                            <h4 className={classes.cardTitle}>
                                {t("promo_33")}
                            </h4>
                        </div>
                        <p className={classes.cardText}>
                            {t("promo_34")}
                        </p>
                    </div>
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t("btn_trade")}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default FirstBonus;
