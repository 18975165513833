import React from "react";
import classes from './TradeMore.module.scss';
import { useTranslation } from "react-i18next";

const TradeMore = () => {
    const { t } = useTranslation();
    const rows_left = [
        {
            col_01: t("promo_13"),
            col_02: '300 USD',
        },
        {
            col_01: t("promo_14"),
            col_02: '300 USD',
        },
        {
            col_01: t("promo_15"),
            col_02: '225 USD',
        },
        {
            col_01: t("promo_16"),
            col_02: t("promo_17"),
        },
        {
            col_01: t("promo_18"),
            col_02: '10 USD',
        },
        {
            col_01: t("promo_19"),
            col_02: '100 USD',
        },
    ];

    const rows_right = [
        {
            col_01: t("promo_20"),
            col_02: t("promo_21"),
        },
        {
            col_01: t("promo_22"),
            col_02: '900 USD',
        },
        {
            col_01: t("promo_23"),
            col_02: '675 USD',
        },
        {
            col_01: t("promo_24"),
            col_02: t("promo_25"),
        },
        {
            col_01: t("promo_26"),
            col_02: '30 USD',
        },
        {
            col_01: t("promo_27"),
            col_02: '300 USD',
        },
    ];
    return (
        <section className="mt container">
            <div className={classes.tradeMore}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("promo_10")}
                </h2>
                <div className={classes.content}>
                    <div className={classes.col}>
                        {rows_left.map((row, index) =>
                            <div key={index} className={classes.row}>
                                <div className={classes.section}>
                                    {row.col_01}
                                </div>
                                <div className={classes.section}>
                                    {row.col_02}
                                </div>
                            </div>
                        )}
                        <p className={classes.desc}>
                            {t("promo_11")}
                        </p>
                    </div>
                    <div className={classes.col}>
                        {rows_right.map((row, index) =>
                            <div key={index} className={classes.row}>
                                <div className={classes.section}>
                                    {row.col_01}
                                </div>
                                <div className={classes.section}>
                                    {row.col_02}
                                </div>
                            </div>
                        )}
                        <p className={classes.desc}>
                            {t("promo_12")}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TradeMore;
