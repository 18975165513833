import React from "react";
import {useTranslation} from "react-i18next";
import classes from "./Trust.module.scss";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Trust = () => {
  const { t } = useTranslation();
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);
  const monetRef4 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top center",
          end: "center center",
          scrub: 2,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top center",
          end: "center center",
          scrub: 3,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef3.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top center",
          end: "center center",
          scrub: 5,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef4.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top center",
          end: "center center",
          scrub: 7,
        },
        y: 0,
      });
    }
  }, [width]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.trust, ""].join(" ")} ref={col1}>
      <div className={[classes.trustBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.trustTitle, "font-36"].join(" ")}>
            {t("main_3")}
          </h2>
          <div className={[classes.trustRow, ""].join(" ")}>
            <div
              className={[classes.trustRowColumn, ""].join(" ")}
              ref={monetRef1}
            >
              <p className={[classes.trustRowColumnTitle, ""].join(" ")}>
                25.7%
              </p>
              <p
                className={[classes.trustRowColumnSubTitle, "font-16"].join(
                  " "
                )}
              >
                {t("main_4")}
              </p>
            </div>
            <div
              className={[classes.trustRowColumn, ""].join(" ")}
              ref={monetRef2}
            >
              <p className={[classes.trustRowColumnTitle, ""].join(" ")}>
                24/7
              </p>
              <p
                className={[classes.trustRowColumnSubTitle, "font-16"].join(
                  " "
                )}
              >
                {t("main_5")}
              </p>
            </div>
            <div
              className={[classes.trustRowColumn, ""].join(" ")}
              ref={monetRef3}
            >
              <p className={[classes.trustRowColumnTitle, ""].join(" ")}>
                {t("main_6")}
              </p>
              <p
                className={[classes.trustRowColumnSubTitle, "font-16"].join(
                  " "
                )}
              >
                {t("main_7")}
              </p>
            </div>
            <div
              className={[classes.trustRowColumn, ""].join(" ")}
              ref={monetRef4}
            >
              <p className={[classes.trustRowColumnTitle, ""].join(" ")}>
                $117.000.000
              </p>
              <p
                className={[classes.trustRowColumnSubTitle, "font-16"].join(
                  " "
                )}
              >
                {t("main_8")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trust;
