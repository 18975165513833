import React from "react";
import {useTranslation} from "react-i18next";
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import imgBull from "../../../assets/img/main/1/bull.png";
import imgCoins from "../../../assets/img/main/1/coins.png";
import imgM1 from "../../../assets/img/main/1/m1.png";
import imgM2 from "../../../assets/img/main/1/m2.png";
import imgM3 from "../../../assets/img/main/1/m3.png";
import imgMob from "../../../assets/img/main/1/mob.png";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
  const { t } = useTranslation();
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);
  const monetRef4 = useRef(null);
  const monetRef5 = useRef(null);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        delay: 0.3,
        duration: 1,
        y: 0,
        x: 0,
        rotate: 0,
      });
      gsap.timeline().to(monetRef2.current, {
        delay: 0.3,
        duration: 1,
        y: 0,
        x: 0,
        rotate: 0,
      });

      gsap.timeline().to(monetRef3.current, {
        delay: 0.3,
        duration: 1,
        y: 0,
        x: 0,
        rotate: 0,
      });
      gsap.timeline().to(monetRef4.current, {
        delay: 0.3,
        duration: 1,
        y: 0,

        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef5.current, {
        delay: 0.3,
        duration: 1,
        scale: 1,
      });
    }
  }, [width]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.banner, ""].join(" ")}>
      <div className={[classes.bannerBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.bannerRow, ""].join(" ")}>
            <div className={[classes.bannerRowLeft, ""].join(" ")}>
              <h1 className={[classes.bannerRowLeftTitle, "font-36"].join(" ")}>
                {t("main_1")}
              </h1>
              <p className={[classes.bannerRowLeftText, "font-16"].join(" ")}>
                {t("main_2")}
              </p>
              <div className={[classes.bannerRowLeftBtn, ""].join(" ")}>
                <Button>{t("btn_trade")}</Button>
              </div>
            </div>
            <div className={[classes.bannerRowImgs, ""].join(" ")}>
              <div
                className={[classes.bannerRowImgsBull, ""].join(" ")}
                ref={monetRef5}
              >
                <img
                  src={imgBull}
                  alt=""
                />
              </div>
              <div
                className={[classes.bannerRowImgsCoins, ""].join(" ")}
                ref={monetRef4}
              >
                <img
                  src={imgCoins}
                  alt=""
                />
              </div>
              <div
                className={[classes.bannerRowImgsMonet1, ""].join(" ")}
                ref={monetRef1}
              >
                <img
                  src={imgM1}
                  alt=""
                />
              </div>
              <div
                className={[classes.bannerRowImgsMonet2, ""].join(" ")}
                ref={monetRef2}
              >
                <img
                  src={imgM2}
                  alt=""
                />
              </div>
              <div
                className={[classes.bannerRowImgsMonet3, ""].join(" ")}
                ref={monetRef3}
              >
                <img
                  src={imgM3}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className={[classes.bannerMob, ""].join(" ")}>
          <img
            src={imgMob}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
