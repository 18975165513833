import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/banner.png';
import Button from "../../GlobalComponents/Button/Button";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-36'].join(' ')}>
                        {t("about_1")}
                    </h1>
                    <div className={[classes.text, 'font-16'].join(' ')}>
                        <p>
                            {t("about_2")}
                        </p>
                        <p>
                            {t("about_3")}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t("btn_trade")}
                        </Button>
                    </div>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
