import HomePage from '../pages/HomePage/HomePage';
import PricingPage from '../pages/PricingPage/PricingPage';
import ProductsPage from '../pages/ProductsPage/ProductsPage';
import PromotionsPage from '../pages/PromotionsPage/PromotionsPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/pricing', element: PricingPage },
    { path: '/products', element: ProductsPage },
    { path: '/promotions', element: PromotionsPage },
    { path: '/faq', element: FaqPage },
    { path: '/about', element: AboutPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/pricing', element: PricingPage },
    { path: '/:lang/products', element: ProductsPage },
    { path: '/:lang/promotions', element: PromotionsPage },
    { path: '/:lang/faq', element: FaqPage },
    { path: '/:lang/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: 'pricing', text: 'header_1' },
    { id: 2, path: 'products', text: 'header_2' },
    { id: 3, path: 'promotions', text: 'header_3' },
    { id: 4, path: 'faq', text: 'header_4' },
    { id: 5, path: 'about', text: 'header_5' },
];
