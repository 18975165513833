import logo from '../assets/img/logo.png';
import img1 from "../assets/img/AboutPage/banner.png";
import img2 from "../assets/img/FaqPage/bg.jpg";
import img3 from "../assets/img/FaqPage/faq.png";
import img4 from "../assets/img/FaqPage/arrow.png";
import img5 from "../assets/img/PricingPage/Banner/coins_center.png";
import img6 from "../assets/img/PricingPage/Banner/coins_left.png";
import img7 from "../assets/img/PricingPage/Banner/coinst_right.png";
import img8 from "../assets/img/PricingPage/Banner/man.png";
import img9 from "../assets/img/PricingPage/Banner/mob.png";
import img10 from "../assets/img/PricingPage/Banner/money_01.png";
import img11 from "../assets/img/PricingPage/Banner/money_02.png";
import img12 from "../assets/img/ProductsPage/banner.png";
import img13 from "../assets/img/PromotionsPage/Banner/200.png";
import img14 from "../assets/img/PromotionsPage/Banner/woman.png";
import img15 from "../assets/img/main/1/bull.png";
import img16 from "../assets/img/main/1/coins.png";
import img17 from "../assets/img/main/1/m1.png";
import img18 from "../assets/img/main/1/m2.png";
import img19 from "../assets/img/main/1/m3.png";
import img20 from "../assets/img/main/1/mob.png";

export const images = [
    logo,
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
];
