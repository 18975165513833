import React, { useEffect, useRef } from "react";
import classes from './Safely.module.scss';
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Safely = () => {
    const { t } = useTranslation();
    const itemRef_01 = useRef(null);
    const itemRef_02 = useRef(null);
    const itemRef_03 = useRef(null);
    const bodyRef = useRef(null);
    const list = [
        {
            ref: itemRef_01,
            title: t("pricing_18"),
            text: t("pricing_19"),
        },
        {
            ref: itemRef_02,
            title: t("pricing_20"),
            text: t("pricing_21"),
        },
        {
            ref: itemRef_03,
            title: t("pricing_22"),
            text: t("pricing_23"),
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(itemRef_01.current, {
                y: 50,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.fromTo(itemRef_02.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.fromTo(itemRef_03.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.safely}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("pricing_24")}
                </h2>
                <p className={[classes.text, 'font-16'].join(' ')}>
                    {t("pricing_25")}
                </p>
                <div ref={bodyRef} className={classes.content}>
                    {list.map((item, index) =>
                        <div ref={item.ref} key={index} className={classes.item}>
                            <h4 className={classes.itemTitle}>
                                {item.title}
                            </h4>
                            <p className={[classes.itemText, 'font-16'].join(' ')}>
                                {item.text}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Safely;
