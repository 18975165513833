import React from "react";
import classes from './OurAdvantages.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const OurAdvantages = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.ourAdvantages}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("about_13")}
                </h2>
                <div className={[classes.content, 'font-16'].join(' ')}>
                    <div className={classes.col}>
                        <p>
                            {t("about_14")}
                        </p>
                        <p>
                            {t("about_15")}
                        </p>
                    </div>
                    <div className={classes.col}>
                        <p>
                            {t("about_16")}
                        </p>
                        <p>
                            {t("about_17")}
                        </p>
                    </div>
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t("btn_trade")}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default OurAdvantages;
