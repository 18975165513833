import React from "react";
import classes from "./About.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import {useTranslation} from "react-i18next";

const About = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.about, "mt"].join(" ")}>
            <div className={`container`}>
                <h2 className={`${classes.about__title}`}>{t("main_47")}</h2>
                <div className={`${classes.about__content}`}>
                    <p>{t("main_48")}</p>
                    <p>{t("main_49")}</p>
                </div>
                <Button>{t("btn_more")}</Button>
            </div>
        </div>
    );
};

export default About;
