import React from "react";
import classes from './GeneralInfo.module.scss';
import { useTranslation } from "react-i18next";

const GeneralInfo = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.generalInfo}>
                <h2 className={[classes.title, 'font-32'].join(' ')}>
                    {t("pricing_13")}
                </h2>
                <div className={classes.content}>
                    <div className={classes.col}>
                        <ul className="font-16">
                            <li>
                                {t("pricing_14")}
                            </li>
                            <li>
                                {t("pricing_15")}
                            </li>
                        </ul>
                    </div>
                    <div className={classes.col}>
                        <ul className="font-16">
                            <li>
                                {t("pricing_16")}
                            </li>
                            <li>
                                {t("pricing_17")}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralInfo;
