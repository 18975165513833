import React from "react";
import classes from './OurAwards.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/AboutPage/OurAwards/award_01.png';
import image_02 from '../../../assets/img/AboutPage/OurAwards/award_02.png';
import image_03 from '../../../assets/img/AboutPage/OurAwards/award_03.png';
import image_04 from '../../../assets/img/AboutPage/OurAwards/award_04.png';
import image_05 from '../../../assets/img/AboutPage/OurAwards/award_05.png';

const awards = [
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
];

const OurAwards = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.ourAwards}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("main_51")}
                </h2>
                <div className={classes.awards}>
                    {awards.map((award, index) =>
                        <div key={index} className={classes.award}>
                            <img src={award} alt=''/>
                        </div>
                    )}  
                </div>
            </div>
        </section>
    );
};

export default OurAwards;
