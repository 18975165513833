import React from "react";
import classes from "./Open.module.scss";
import img1 from "../../../assets/img/main/4/1.png";
import img2 from "../../../assets/img/main/4/2.png";
import img3 from "../../../assets/img/main/4/3.png";

import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import {useTranslation} from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Open = () => {
  const { t } = useTranslation();
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,

          scrub: 2,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,

          scrub: 3,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef3.current, {
        scrollTrigger: {
          trigger: col1.current,

          scrub: 5,
        },
        y: 0,
      });
    }
  }, [width]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.open, "mt"].join(" ")} ref={col1}>
      <div className={[classes.openBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.openTitle, "font-36"].join(" ")}>
            {t("main_25")}
          </h2>
          <div className={[classes.openRow, ""].join(" ")}>
            <div
              className={[classes.openRowColumn, ""].join(" ")}
              ref={monetRef1}
            >
              <div className={[classes.openRowColumnImg, ""].join(" ")}>
                <img src={img1} alt="" />
              </div>
              <p className={[classes.openRowColumnText, "font-16"].join(" ")}>
                {t("main_26")}
              </p>
            </div>
            <div
              className={[classes.openRowColumn, ""].join(" ")}
              ref={monetRef2}
            >
              <div className={[classes.openRowColumnImg, ""].join(" ")}>
                <img src={img2} alt="" />
              </div>
              <p className={[classes.openRowColumnText, "font-16"].join(" ")}>
                {t("main_27")}
              </p>
            </div>
            <div
              className={[classes.openRowColumn, ""].join(" ")}
              ref={monetRef3}
            >
              <div className={[classes.openRowColumnImg, ""].join(" ")}>
                <img src={img3} alt="" />
              </div>
              <p className={[classes.openRowColumnText, "font-16"].join(" ")}>
                {t("main_28")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Open;
