import React from "react";
import classes from './BestBonus.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/PromotionsPage/BestBonus/image_01.png';
import image_02 from '../../../assets/img/PromotionsPage/BestBonus/image_02.png';
import image_03 from '../../../assets/img/PromotionsPage/BestBonus/image_03.png';


const BestBonus = () => {
    const { t } = useTranslation();
    const cards = [
        {
            image: image_01,
            title: t("promo_4"),
            text: t("promo_5"),
        },
        {
            image: image_02,
            title: t("promo_6"),
            text: t("promo_7"),
        },
        {
            image: image_03,
            title: t("promo_8"),
            text: t("promo_9"),
        },
    ];
    return (
        <section className="mt container">
            <div className={classes.bestBonus}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("promo_3")}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={[classes.card, 'font-16'].join(' ')}>
                            <img className={classes.cardImage} src={card.image} alt=''/>
                            <h4 className={classes.cardTitle}>
                                {card.title}
                            </h4>
                            <p className={classes.cardText}>
                                <Trans>
                                    {card.text}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default BestBonus;
