import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import woman from '../../../assets/img/PromotionsPage/Banner/woman.png';
import per200 from '../../../assets/img/PromotionsPage/Banner/200.png';
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const per200Ref = useRef(null);
    const womanRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(womanRef.current, {
                scale: 0.9,
                y: 30,
            }, {
                scale: 1,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(per200Ref.current, {
                scale: 0.8,
            }, {
                scale: 1,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={classes.title}>
                        <span className={classes.title_01}>
                            <p>
                                 {t("promo_1")}
                            </p>
                            <img ref={per200Ref} className={classes.per200} src={per200} alt=''/>
                        </span>
                        <span className={classes.title_02}>
                            {t("promo_2")}
                        </span>
                    </h1>
                    <div className={classes.btn}>
                        <Button>
                            {t("btn_trade")}
                        </Button>
                    </div>
                </div>
                <img ref={womanRef} className={classes.image} src={woman} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
