import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';

const Button = ({children, login}) => {
  const lang = Cookies.get('i18next');
  const CABINET_URL = 'https://user.est-invest.co';
  return (
    <>
      {login ?
        <a href={`${CABINET_URL}/login?lang=${lang}`} className={`${classes.button}`}>
          <span>{children}</span>
        </a>
        :
        <a href={`${CABINET_URL}/signup?lang=${lang}`} className={`${classes.button}`}>
          <span>{children}</span>
        </a>
      }
    </>
  );
};

export default Button;
