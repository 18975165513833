import React from "react";
import classes from './ForEveryone.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AboutPage/for_everyone.png';
import Button from "../../GlobalComponents/Button/Button";

const ForEveryone = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.forEveryone}>
                <div className={classes.col}>
                    <h2 className={[classes.title, 'font-36'].join(' ')}>
                        {t("about_11")}
                    </h2>
                    <p className={[classes.text, 'font-16'].join(' ')}>
                        {t("about_12")}
                    </p>
                    <div className={classes.btn}>
                        <Button>
                            {t("btn_trade")}
                        </Button>
                    </div>
                </div>
                <div className={classes.col}>
                    <img className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default ForEveryone;
