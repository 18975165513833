import classes from "./Included.module.scss";
import img1 from "../../../assets/img/main/3/1.png";
import Button from "../../GlobalComponents/Button/Button";
import imgMob from "../../../assets/img/main/3/mob.png";

import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import {useTranslation} from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Included = () => {
  const { t } = useTranslation();
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "center center",
          scrub: 2,
        },
        x: 0,
      });
    }
  }, [width]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.included, "mt"].join(" ")} ref={col1}>
      <div className={[classes.includedBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.includedTitle, "font-36"].join(" ")}>
            {t("main_18")}
          </h2>
          <div className={[classes.includedRow, ""].join(" ")}>
            <div
              className={[classes.includedRowLeft, ""].join(" ")}
              ref={monetRef1}
            >
              <img src={img1} alt="" />
            </div>
            <div className={[classes.includedRowRight, ""].join(" ")}>
              <p
                className={[classes.includedRowRightText, "font-16"].join(" ")}
              >
                <span>{t("main_19")}</span>
                {t("main_20")}
              </p>
              <p
                className={[classes.includedRowRightText, "font-16"].join(" ")}
              >
                <span>{t("main_21")}</span>
                {t("main_22")}
              </p>
              <p
                className={[classes.includedRowRightText, "font-16"].join(" ")}
              >
                <span>{t("main_23")}</span>
                {t("main_24")}
              </p>
              <div className={[classes.includedRowRightBtn, ""].join(" ")}>
                <Button>{t("btn_trade")}</Button>
              </div>
            </div>
          </div>
          <div className={[classes.includedMob, ""].join(" ")}>
            <img src={imgMob} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Included;
