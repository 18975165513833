import React, { useEffect, useRef } from "react";
import classes from './Expand.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/ProductsPage/Expand/image_01.png';
import image_02 from '../../../assets/img/ProductsPage/Expand/image_02.png';
import image_03 from '../../../assets/img/ProductsPage/Expand/image_03.png';
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});


const Expand = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const bodyRef = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            image: image_01,
            title: t("products_8"),
            text: t("products_9"),
        },
        {
            ref: cardRef_02,
            image: image_02,
            title: t("products_10"),
            text: t("products_11"),
        },
        {
            ref: cardRef_03,
            image: image_03,
            title: t("products_12"),
            text: t("products_13"),
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 50,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                },
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                },
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                },
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.expand}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    {t("products_7")}
                </h2>
                <div ref={bodyRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={[classes.card, 'font-16'].join(' ')}>
                            <img className={classes.cardImage} src={card.image} alt=''/>
                            <h4 className={classes.cardTitle}>
                                {card.title}
                            </h4>
                            <p className={classes.cardText}>
                                {card.text}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t("btn_trade")}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default Expand;
