import React from "react";
import classes from "./Best.module.scss";
import {useTranslation} from "react-i18next";
const Best = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.best, "mt"].join(" ")}>
      <div className={[classes.bestBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.bestTitle, "font-32"].join(" ")}>
            {t("main_29")}
          </h2>
          <div className={[classes.bestRow, ""].join(" ")}>
            <div className={[classes.bestRowLeft, ""].join(" ")}>
              <p className={[classes.bestRowLeftText, "font-16"].join(" ")}>
                {t("main_30")}
              </p>
              <p className={[classes.bestRowLeftText, "font-16"].join(" ")}>
                {t("main_31")}
              </p>
            </div>
            <div className={[classes.bestRowRight, ""].join(" ")}>
              <ul className={[classes.bestRowRightList, ""].join(" ")}>
                <li
                  className={[classes.bestRowRightListItem, "font-16"].join(
                    " "
                  )}
                >
                  {t("main_32")}
                </li>
                <li
                  className={[classes.bestRowRightListItem, "font-16"].join(
                    " "
                  )}
                >
                  {t("main_33")}
                </li>
                <li
                  className={[classes.bestRowRightListItem, "font-16"].join(
                    " "
                  )}
                >
                  {t("main_34")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Best;
