import React, { useState } from "react";
import classes from "./Faq.module.scss";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      title: t("main_53"),
      text: t("main_54"),
    },
    {
      title: t("main_55"),
      text: t("main_56"),
    },
    {
      title: t("main_57"),
      text: t("main_58"),
    },
    // {
    //   title: t("main_59"),
    //   text: t("main_60"),
    // },
    {
      title: t("main_61"),
      text: t("main_62"),
    },
    {
      title: t("main_63"),
      text: t("main_64"),
    },
    {
      title: t("main_65"),
      text: t("main_66"),
    },
  ];

  const [currentTab, setCurrentTab] = useState(0);
  const openTab = (index) => {
    if (currentTab === index) {
      setCurrentTab(-1);
      return;
    }
    setCurrentTab(index);
  };
  return (
    <section className="mt container">
      <div className={classes.faq}>
        <h2 className={[classes.title, "font-36"].join(" ")}>{t("main_52")}</h2>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              className={`${classes.tab} ${
                currentTab === index && classes.tabActive
              } no-select font-16`}
              onClick={() => openTab(index)}
              key={index}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>{tab.title}</div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p className="font-16">{tab.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
